import React from 'react';
import Layout from '../components/Layout';
import { graphql } from 'gatsby';
import ListItemLink from '../base/ListItemLink';
import styled from '@emotion/styled';
import Footer from '../components/Footer';
import PageHeader from '../components/PageHeader';
import { Box } from '@3pc/layout-components-react';
import { colors } from '../styles/colors';
import { devices } from '../styles/breakpoints';
import { headerHeight } from '../components/PageHeader';
import { footerHeight } from '../components/Footer';
import { sortNums } from '../utils';
import ResponsiveImage from '../base/ResponsiveImage';

/* MaxWidth of the Fluid in the query should be equal to  the value of pageMaxWidth key of maxWidthVals object in styles/container.js */

export const query = graphql`
  query($slug: String!) {
    mdx(slug: { eq: $slug }) {
      frontmatter {
        topicTitle
        title
        categoryNumber
      }
      body
    }
    links: allFile(
      filter: { childMdx: { frontmatter: { type: { eq: $slug } } } }
    ) {
      nodes {
        childMdx {
          frontmatter {
            slug
            title
            type
            order
          }
          body
        }
      }
    }
    image: file(relativePath: { eq: "2.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          aspectRatio: 3.10303
          transformOptions: { grayscale: true, cropFocus: NORTH }
          placeholder: BLURRED
        )
      }
    }
    mobileImage: file(relativePath: { eq: "2.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          aspectRatio: 1.77778
          transformOptions: { grayscale: true, cropFocus: NORTH }
          placeholder: BLURRED
        )
      }
    }
  }
`;

const PageTitle = styled.h1`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  text-align: center;
  color: ${colors.white};
  font-size: 1.25em;
  line-height: 1.25em;
  width: 100%;
  padding: 0 20px;

  @media ${devices.mobileMin} {
    font-size: 1.875em;
    line-height: 2.25em;
  }

  span {
    font-size: 0.875em;
    line-height: 1em;
    margin-bottom: 10px;

    @media ${devices.mobileMin} {
      font-size: 1em;
      line-height: 1.25em;
    }
  }
`;

const TitleInfo = styled.span`
  display: block;
`;

const BackgroundPage = (data) => {
  const defaultImage = data.data.image.childImageSharp.gatsbyImageData;
  const mobileImage = data.data.mobileImage.childImageSharp.gatsbyImageData;
  const altTag =
    'Gemälde der Frankfurter Nationalversammlung in der Paulskirche 1848; voller Saal mit Menschen und Blick auf das Rednerpult';

  /* TO DO: On mount check if the list item is bookmarked */

  return (
    <>
      <PageHeader headerTitle={'Hintergrund'} pageType={'list-page'} />
      <Layout>
        <Box as="section" mt={headerHeight.headerMobile}>
          <Box as="header" position="relative" aria-labelledby="header-title">
            <ResponsiveImage
              defaultImage={defaultImage}
              mobileImage={mobileImage}
              altTag={altTag}
            />
            <PageTitle>
              {data.data.mdx.frontmatter.categoryNumber && (
                <TitleInfo id="header-title">
                  {`${data.data.mdx.frontmatter.categoryNumber}.`}
                </TitleInfo>
              )}

              {data.data.mdx.frontmatter.title}
            </PageTitle>
          </Box>
          <Box padding="15px 0 20px 20px" mb={footerHeight.footerMobile}>
            <nav aria-labelledby="headerNavTitle">
              <p id="headerNavTitle" className="sr-only">
                Hintergrund Seite Navigation
              </p>
              <Box as="ol" margin={['12px 0 0 0', '17px 15% 0 15%']}>
                {data.data.links.nodes
                  .sort((a, b) => {
                    return sortNums(
                      a.childMdx.frontmatter.order,
                      b.childMdx.frontmatter.order
                    );
                  })
                  .map((file, index) => (
                    <ListItemLink
                      key={`sublist-${index}`}
                      link={`/${file.childMdx.frontmatter.slug}`}
                      title={file.childMdx.frontmatter.title}
                    />
                  ))}
              </Box>
            </nav>
          </Box>
        </Box>
      </Layout>
      <Footer />
    </>
  );
};

export default BackgroundPage;
